/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useState } from 'react'
import { Sky, Cloud, useScroll, useGLTF, Text } from '@react-three/drei'
import Rocket from './Rocket'

import { Canvas, useFrame } from '@react-three/fiber'
import { useSpring, animated } from 'react-spring'
import { StereoCamera } from 'three'


export default function Model({ ...props }) {
  const group = useRef()
  const scroll = useScroll()
  const { nodes, materials } = useGLTF('/polar_bear_banner.glb')
  const [camera_pos, LookAtCamera] = useState(null)
  const [rayleigh, SetRayleigh] = useState("-1");
  const [smoke_opacity, SetSmokeOpacity] = useState(0);

  const spring = useSpring({
    loop: true,
    to: [
      { visible: 1, scale: 0 },
      { visible: 1, scale: 2 },
    ],
    from: { visible: 1, scale: 5 },
  })

  useFrame((state, delta) => {
    // The offset is between 0 and 1, you can apply it to your models any way you like
    const offset = scroll.offset
    group.current.rotation.x = offset*20+0.5

    console.log(offset*-1)
    SetRayleigh(offset+1*-1)
    SetSmokeOpacity(offset)

    state.camera.position.set(
      -Math.sin(offset) * -100, 
      Math.atan(offset)-offset*20, 
      Math.cos((offset * Math.PI) / 3) * 8 +offset*10)


    //console.log(state.camera.position)
    //LookAtCamera(state.camera.position)
    //console.log(camera_pos)
    })
    
    //LookAtCamera(camera.position)
    

  const [hovered, setHover] = useState(false)

  //console.log(nodes)
  useFrame((state) => {
    const t = state.clock.getElapsedTime()
    group.current.rotation.x = Math.cos(t / 4) / 64
    group.current.rotation.y = Math.sin(t / 4) / 128
    group.current.position.y = (Math.sin(t / 4.5)) / 40
  })
  return (
    <group 
    ref={group} {...props} >
      <Text
          maxWidth={3}
          scale={[1, 1.5, 1]}
          color="Black" // default
          anchorX="center" // default
          anchorY="middle" // default
          position={[5,0,-5]}
          rotation={[0,-0.5,0]}
          fillOpacity={1}
        >
          "These aren't just artpieces, it is an apology from humanity to our hosts.
            It's for the violence and destruction we do to the environment and to the animals. 
            In Futuristic Bears, these bears are untouchable; nobody can harm them."
      </Text>
      <Text
          maxWidth={3}
          scale={[1.5, 1.5, 1]}
          color="Black" // default
          anchorX="center" // default
          anchorY="middle" // default
          position={[6,-0.75,-5]}
          rotation={[0,-0.5,0]}

        >
        -Spazmic
      </Text>
      <lineSegments geometry={nodes.Mesh.geometry} material={nodes.Mesh.material} material-color={hovered ? props.color2 : props.color1} />
      <mesh
        dispose={null}         
        onPointerOver={(event) => setHover(true)}
        onPointerOut={(event) => setHover(false)}
        geometry={nodes.Tete.geometry}
        material={nodes.Tete.material}
        material-color={hovered ? props.color1 : props.color2}
        position={[0.01, -0.15, 2.94]}
        rotation={[0, -Math.PI / 2, 0]}
        lookAt={camera_pos}
      />
      <mesh
        geometry={nodes.Body.geometry}
        material={nodes.Body.material}
        position={[0.01, -1.16, -1.28]}
        rotation={[0, -Math.PI / 2, 0]}

      />
      <mesh
        geometry={nodes.Jambe_arr_gauche.geometry}
        //onClick={(e) => alert("You clicked daddy's big leg! ;-) You have found the secret treasure!")}
        material={nodes.Jambe_arr_gauche.material}
        position={[0.85, -2.28, -3.88]}
        rotation={[1.66, -1.52, 3.09]}
      />
      {/* Right eye Big */}
      <mesh
        geometry={nodes.Eye1.geometry} 
        material={nodes.Eye1.material} 
        position={[-0.3, 0.27, 3.21]} 
        
        
        />
      <mesh
        geometry={nodes.Ear_left.geometry}
        material={nodes.Ear_left.material}
        position={[0.01, -0.15, 2.94]}
        rotation={[0, -Math.PI / 2, 0]}
      />
      <group position={[0.87, 0.53, 2.54]} rotation={[0, -Math.PI / 2, 0]}>
        <mesh geometry={nodes.Icosphere004.geometry} material={nodes.Icosphere004.material} />
        <points geometry={nodes.Icosphere004_1.geometry} material={nodes.Icosphere004_1.material} />
      </group>
      <group position={[0.01, -0.15, 2.96]} rotation={[0, -Math.PI / 2, 0]}>
        <mesh geometry={nodes.Icosphere010.geometry} material={nodes.Icosphere010.material} material-color="white"/>
        <lineSegments geometry={nodes.Icosphere010_1.geometry} material={nodes.Icosphere010_1.material} />
        <points geometry={nodes.Icosphere010_2.geometry} material={nodes.Icosphere010_2.material}  />
      </group>
      <group position={[0.01, -0.14, 2.94]} rotation={[0, -Math.PI / 2, 0]}>
        <mesh geometry={nodes.Icosphere011.geometry} material={materials.LipMaterial} material-color={hovered ? props.color1 : props.color2}/>
        <lineSegments geometry={nodes.Icosphere011_1.geometry} material={materials.LipMaterial} />
{/*         <points geometry={nodes.Icosphere011_2.geometry} material={materials.LipMaterial} />              This parts fucks off on IOS IPHONE with black square n shit! WTF!*/}
      </group>
      <group position={[0.01, -0.43, 4.28]} rotation={[0.66, -1.57, 0]}>
        <mesh geometry={nodes.Icosphere014.geometry} material={nodes.Icosphere014.material} material-color= "white"/>
        <lineSegments geometry={nodes.Icosphere014_1.geometry} material={nodes.Icosphere014_1.material} />
        <points geometry={nodes.Icosphere014_2.geometry} material={nodes.Icosphere014_2.material} />
      </group>
      {/* left eye big */}
      
      <mesh 
      geometry={nodes.Eye1003.geometry} 
      material={nodes.Eye1003.material} 
      position={[0.31, 0.27, 3.21]}

      scale={1}
            
      />

      <mesh material-color={props.iris_color} geometry={nodes.Eye1004.geometry} material={nodes.Eye1004.material} position={[0.33, 0.31, 3.35]} />
      <mesh geometry={nodes.Eye1005.geometry} material={nodes.Eye1005.material} position={[0.3, 0.31, 3.37]} />
      <mesh geometry={nodes.Eye1001.geometry} material={nodes.Eye1001.material} position={[-0.32, 0.31, 3.35]} />
      <mesh geometry={nodes.Eye1002.geometry} material={nodes.Eye1002.material} position={[-0.29, 0.31, 3.37]} />
      <group position={[0.01, -0.14, 2.92]} rotation={[0, -Math.PI / 2, 0]}>
        <mesh geometry={nodes.Icosphere013.geometry} material={nodes.Icosphere013.material} material-color={hovered ? props.color2 : props.color1}/>
        <lineSegments geometry={nodes.Icosphere013_1.geometry} material={nodes.Icosphere013_1.material} />
        <points geometry={nodes.Icosphere013_2.geometry} material={nodes.Icosphere013_2.material} />
      </group>
      <mesh
        geometry={nodes.Beret.geometry}
        material={nodes.Beret.material}
        material-color={hovered ? props.color1 : props.color3}
        position={[0.14, 0.89, 2.51]}
        rotation={[-0.12, 0.01, -0.18]}
        visible={props.isBeretVisible}
      />
      <mesh geometry={nodes.Cravate.geometry} material={materials.Chain} position={[-0.01, -1.77, 1.96]} material-color="orange" visible={props.isCravateVisible}/>
      <mesh
        geometry={nodes.Beret_pompom.geometry}
        material={nodes.Beret_pompom.material}
        position={[0.19, 1.07, 2.45]}
        rotation={[-0.12, 0.01, -0.18]}
        visible={props.isBeretVisible}
      />
      <mesh
        geometry={nodes.Torus001.geometry}
        material={nodes.Torus001.material}
        position={[0.26, 0.07, 3.98]}
        rotation={[Math.PI / 2, 0, 0]}
        visible={props.areGlassesVisible}
      />
      <mesh
        geometry={nodes.Torus002.geometry}
        material={nodes.Torus002.material}
        position={[-0.26, 0.07, 3.98]}
        rotation={[Math.PI / 2, 0, 0]}
        visible={props.areGlassesVisible}
      />
      {/*Left glass*/}
        <group position={[0.26, 0.07, 3.98]} rotation={[Math.PI / 2, 0, 0]}>
          <mesh geometry={nodes.Torus005.geometry} 
          material={nodes.Torus005.material} 
          visible={props.areGlassesVisible}
          />
          <lineSegments geometry={nodes.Torus005_1.geometry} material={nodes.Torus005_1.material} visible={props.areGlassesVisible}/>
        </group>
      {/*Right glass*/}
        <group position={[-0.26, 0.07, 3.98]} rotation={[Math.PI / 2, 0, 0]}>
          <mesh geometry={nodes.Torus007.geometry} material={nodes.Torus007.material} visible={props.areGlassesVisible} material-color={props.glasses_color}/>
          <lineSegments geometry={nodes.Torus007_1.geometry} material={nodes.Torus007_1.material} visible={props.areGlassesVisible}/>
        </group>
      {/*Tige milieu*/}
        <mesh 
          geometry={nodes.Cube.geometry} 
          material={nodes.Cube.material} 
          position={[0, 0, 3.98]} 
          visible={props.areGlassesVisible} 
          material-color={props.glasses_color}/>
      {/*Branche gauche*/}
        <mesh
          geometry={nodes.Cube001.geometry}
          material={nodes.Cube001.material}
          material-color={hovered ? 'Purple' : 'Gold'}
          position={[0.78, 0.56, 2.74]}
          rotation={[0.36, -0.28, 0.16]}
          visible={props.areGlassesVisible}
          material-color={props.glasses_color}
        />
      {/*Branche droite*/}
        <mesh
          geometry={nodes.Cube002.geometry}
          material={nodes.Cube002.material}
          position={[-0.78, 0.56, 2.74]}
          rotation={[0.36, 0.28, -0.16]}
          visible={props.areGlassesVisible}
          material-color={props.glasses_color}
        />
        <Rocket position={[0,-7.35,-3]} rotation={[-0.05,0,0]} scale={4}/>
        <Sky distance={450000} sunPosition={[0, -3, 0]} inclination={0} azimuth={0.25} turbidity={0} rayleigh={rayleigh}/>
        <Cloud position={[0,-8,-60]} speed={6} opacity={smoke_opacity} depth={1} width={1} segments={75} visible={true} rotation={[0,0,0]} />
        
    </group>
    
  )
}

useGLTF.preload('/polar_bear_banner.glb')
