import React, { Suspense, useRef, useState } from 'react'
import * as THREE from 'three'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { Cloud, Loader, OrbitControls, ScrollControls, Stars, Sky, useScroll, Scroll } from '@react-three/drei'
import { useSpring } from '@react-spring/three'
import { EffectComposer, DepthOfField, Noise, Vignette, SSAO } from '@react-three/postprocessing'
import { Physics, useBox } from '@react-three/cannon'
import ModelBear from './Polar_bear_banner'
import Asteroid from './Asteroid'
import Player from './Player'
import Grass from './Grass'
import Fire from './resources/shaders/Fire'
import City from './City_drei'

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/


export default function App() {
  let colors = ["Blue", "DarkGreen", "Red", "Orange", "Violet", "Indigo", "Yellow"];

  let audio = new Audio("/alien-spaceship_daniel_simion.mp3")
  const start = () => {
    audio.play()
  }
  const data = useScroll()




  const [titlecolor, SetTitleColor] = useState(null);
  const [bear_color_1, SetBearColor1] = useState("purple");
  const [bear_color_2, SetBearColor2] = useState("indigo");
  const [bear_color_3, SetBearColor3] = useState("Blue");
  const [areGlassesVisible, SetGlassesVisible] = useState(true);
  const [isBeretVisible, SetBeretVisible] = useState(true);
  const [isCravateVisible, SetCravateVisible] = useState(true);
  const [glasses_color, SetGlassesColor] = useState("gold")
  const [cravate_color, SetCravateColor] = useState("red")
  const [glass_opacity, SetGlassOpacity] = useState("1")
  const [iris_color, SetIrisColor] = useState("white")
  const [pupil_color, SetPupilColor] = useState("black")

  React.useEffect(() => {
    const timer = setInterval(() => {
      const random1 = Math.floor(Math.random() * colors.length);
      const random2 = Math.floor(Math.random() * colors.length);
      const random3 = Math.floor(Math.random() * colors.length);
      const random4 = Math.floor(Math.random() * colors.length);
      const random5 = Math.floor(Math.random() * colors.length);
      const random6 = Math.floor(Math.random() * colors.length);
      SetBearColor1(colors[random1])
      SetBearColor2(colors[random1])
      SetBearColor3(colors[random3])
      SetGlassesVisible(Math.random() < 0.6)
      SetBeretVisible(Math.random() < 0.35)
      SetCravateVisible(Math.random() < 0.2)
      SetGlassesColor(colors[random4])
      SetCravateColor(colors[random5])
      SetIrisColor(colors[random2])
      SetPupilColor(colors[random6])
    }, 2000);

    return () => clearTimeout(timer);
  }, []);



  return (
    <>
      <Canvas dpr={[1.5, 2]} linear shadows camera={{position:[0, 2, 1], fov:40}}>
        {/* <fog attach="fog" args={[bear_color_1 , 10, 40]} /> */}
        <ambientLight intensity={0.75} />

          <pointLight intensity={0.75} position={[0, -5, -5]} />
          <pointLight intensity={0.75} position={[0, 5, 5]} />
          <spotLight castShadow intensity={0.25} angle={2} penumbra={1} position={[0, 2, 30]} shadow-mapSize={[1024, 1024]} shadow-bias={-0.0001} />

        <Suspense fallback={null}>
{/*               <Cloud position={[-20,-5,2]} speed={0.4} opacity={1} depth={1} width={1}/>
              <Cloud position={[-10,-7,2]} speed={0.4} opacity={1} depth={1} width={1}/> */}
              
{/*               <Cloud position={[10,-7,2]} speed={0.4} opacity={1} depth={1} width={1}/>
              <Cloud position={[20,-5,2]} speed={0.4} opacity={1} depth={1} width={1}/>
              <Cloud position={[50,-2,0]} speed={0.4} opacity={1} depth={1} width={1}/> */}
              
            <ScrollControls pages={3} damping={1} horizontal distance={1}>
            <Scroll html>
                  <h1 style={{ position: 'absolute', top: '64vh', left: '20vw', fontSize: '2vw', color:'black' }}>Now Available!<br/><br/></h1>
                  <h1 style={{ position: 'absolute', top: '64vh', left: '120vw', fontSize: '2vw' }}>going</h1>
                  <h1 style={{ position: 'absolute', top: '64vh', left: '180vw', fontSize: '2vw' }}> to  the moon!</h1> 


            </Scroll>
            <Scroll>
                <ModelBear 
                  color1={bear_color_1} 
                  color2={bear_color_2} 
                  color3={bear_color_3} 
                  areGlassesVisible={areGlassesVisible} 
                  isBeretVisible={isBeretVisible} 
                  isCravateVisible={isCravateVisible} 
                  glasses_color={glasses_color}
                  cravate_color={cravate_color}
                  iris_color={iris_color}
                  pupil_color={pupil_color}
                />
                {/* <Fire position={[0,0,-35]}/> */}
              </Scroll>
            </ScrollControls>
            {/* <Asteroid position={[0, 2, 2]}/> */}
            <City />
        </Suspense>


        <OrbitControls minDistance={8} maxDistance={9.2} enableRotate={false} enablePan={false} enableZoom={false} maxPolarAngle={Math.PI / 2} minPolarAngle={Math.PI / 2} />
        <Stars radius={500} depth={50} count={10000} factor={10} />
        <EffectComposer>
          <Noise opacity={0.05} />
        </EffectComposer>

        
      </Canvas>
      
      <div className="layer" />
      <Loader />
      <h1 style={{
                    color: titlecolor,
                    position: 'absolute',
                    top: '6vh', 
                    left: '0.5em'
                  }}>
                    FuturisticBears NFTs
                    <br />
                    <span style={{ fontSize: '0.4em' }}>by Spazmic</span>
                    {/*         < div >
                      <button onClick={start}>Play</button>
                    </div > */}
        </h1>

      <a href="https://polarbearsinternational.org/" className="top-right" children="Help a bear!" />
      <a href="https://opensea.io/collection/futuristicbears" className="myButton" children="SEE COLLECTION @ OPENSEA.IO "/>
    

      {/* <div className="bg" /> */}



    </>
  )
}
