/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: jemepousse (https://sketchfab.com/jemepousse)
license: CC-BY-4.0 (http://creativecommons.org/licenses/by/4.0/)
source: https://sketchfab.com/models/356c773c4b9a45d8b9d4aa04c60ecb27
title: Red City
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'

export default function Model({ ...props }) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/city_drei.glb')
  return (
    <group ref={group} position={[0, -55, 0]} scale={[0.001, 0.001, 0.001]} {...props} dispose={null}>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group position={[-102253.52, -210688.86, -17050.52]}>
          <mesh geometry={nodes.mesh_0.geometry} material={nodes.mesh_0.material} />
          <mesh geometry={nodes.mesh_1.geometry} material={nodes.mesh_1.material} />
          <mesh geometry={nodes.mesh_2.geometry} material={nodes.mesh_2.material} />
          <mesh geometry={nodes.mesh_3.geometry} material={nodes.mesh_3.material} />
        </group>
      </group>
    </group>
  )
}

useGLTF.preload('/city_drei.glb')
